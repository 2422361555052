import packageJson from '../package.json';

export const DELIVERY = 'delivery';
export const PICKUP = 'pickup';
export const DINE_IN = 'dine-in';
export const PICKUP_DELIVERY = 'pickupDelivery';
export const PICKUP_DINE_IN = 'pickupDineIn';
export const DELIVERY_DINE_IN = 'deliveryDineIn';
export const PICKUP_DELIVERY_DINE_IN = 'pickupDeliveryDineIn';
export const ORDERTYPES_TO_SINGULAR_TYPES = {
  [DELIVERY]: [DELIVERY],
  [PICKUP]: [PICKUP],
  [DINE_IN]: [DINE_IN],
  [PICKUP_DELIVERY]: [PICKUP, DELIVERY],
  [PICKUP_DINE_IN]: [PICKUP, DINE_IN],
  [DELIVERY_DINE_IN]: [DELIVERY, DINE_IN],
  [PICKUP_DELIVERY_DINE_IN]: [PICKUP, DELIVERY, DINE_IN],
};

export const API_VERSION = 'v1';
export const API_VERSION_V1 = 'v1';
export const API_VERSION_V2 = 'v2';
export const PAYMENT_URL_SERVER = 'payment';
export const WHOLESALE_PAYMENT_URL_SERVER = 'wholesalePayment';
export const VERIFY_PROMO_CODE_SERVER_URL = 'verifyPromoCode';
export const AVAILABILITY_STATUS_FOR_ITEMS_IN_CART =
  'availability-status-for-items-in-cart';
export const SOLD_OUT_ITEMS_IN_CART = 'sold-out-items-in-cart';

export const PAYMENT_URL = '/checkout';
export const PAYMENT_SUCCESS_URL = '/checkout/payment-success';
export const STRIPE_PROVIDER_API_KEY =
  'pk_live_fTqz7roTCzEya7MApuWOR5Mp00N0cJI5Q0';

// aggressively reduce the quality for smaller mobile screens, q=25
export const IMAGE_MOBILE_FORMATTING = 'auto=compress&fit=clamp&q=25&fm=png';
export const HERO_IMAGE_FILENAME = 'hero_1.png';

export const DAY_ORDER = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const MAX_LENGTH_USER_INSTRUCTIONS = 140;

export const ADD = 'add';
export const SUBTRACT = 'subtract';

// legacy handling: of delivery and promotion items which were
// put in cart. they no longer are
export const DELIVERY_SURCHARGE_TITLE = 'Delivery surcharge';
export const PROMOTION = 'promotion';

export const PROMOTION_PERCENT_TYPE = 'percent';
export const PROMOTION_AMOUNT_TYPE = 'amount';

export const RETAIL_ONLY = 'retailOnly';
export const WHOLESALE_ONLY = 'wholesaleOnly';
export const RETAIL_AND_WHOLESALE = 'retailAndWholesale';

// used to see what version of client app each customer uses during ordering
export const CLIENT_APP_VERSION = packageJson.version;

// order 'wanted at' feature: different options/combinations
export const WANTED_AT_TIME = 'time';
export const WANTED_AT_DATE = 'date';
export const WANTED_AT_TIME_DATE = 'timeDate';
export const WANTED_AT_NONE = 'none';
export const WANTED_AT_TYPES = {
  [WANTED_AT_TIME]: WANTED_AT_TIME,
  [WANTED_AT_DATE]: WANTED_AT_DATE,
  [WANTED_AT_TIME_DATE]: WANTED_AT_TIME_DATE,
  [WANTED_AT_NONE]: WANTED_AT_NONE,
};

export const OVERRIDE_OPEN_TYPE = 'open';
export const OVERRIDE_CLOSED_TYPE = 'closed';

export const OVERRIDE_DAYS_FIRESTORE_COLLECTION = 'overrideDays';
export const OPERATING_HOURS_FIRESTORE_COLLECTION = 'operatingHours';

export const TODAY = 'today';
export const FUTURE = 'future';

export const HEARTED_ITEMS_COLLECTION_REF = 'heartedItemsCollection';

export const ADD_TO_ACCOUNT = 'add_to_account';
export const PAY_NOW = 'pay_now';

export const CHANNEL_WHOLESALE = 'wholesale';
export const CHANNEL_RETAIL = 'retail';
export const WHOLESALE_BILL_TO_ACCOUNT = 'bill_to_account';
export const WHOLESALE_PAID_CREDIT_CARD = 'paid_credit_card';

export const GST_INCLUDED = 'gstIncluded';
export const GST_EXCLUDED = 'gstExcluded';
export const GST_NONE = 'gstNone';

export const PAYMENT_TERMS_7_DAYS = '7days';
export const PAYMENT_TERMS_14_DAYS = '14days';
export const PAYMENT_TERMS_21_DAYS = '21days';
export const PAYMENT_TERMS_28_DAYS = '28days';
export const PAYMENT_TERMS_END_OF_EACH_WEEK_AUTOCHARGE_ACCOUNT =
  'endOfEachWeekAutochargeAccount';
export const PAYMENT_TERMS_END_OF_EACH_MONTH_AUTOCHARGE_ACCOUNT =
  'endOfEachMonthAuthchargeAccount';

export const ISO_DATESTRING_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ';

// to times to select when order wanted
export const INCREMENT_MINUTES_DEFAULT = 15;

export const DELIVERY_CHECK_TYPE_RADIUS = 'radius';
export const DELIVERY_CHECK_TYPE_POSTCODES = 'postCodes';

// delivery fee modes and algos for dynamic delivery
export const DELIVERY_FEE_MODE_STATIC = 'static';
export const DELIVERY_FEE_MODE_DYNAMIC = 'dynamic';
export const DELIVERY_FEE_DYNAMIC_COST_FUNC_VALLEY = 'valley';
export const DELIVERY_FEE_DYNAMIC_COST_FUNC_LOGISTIC = 'logistic';
export const DELIVERY_FEE_DYNAMIC_COST_FUNC_LINEAR = 'linear';
export const DELIVERY_FEE_DYNAMIC_COST_FUNC_STEPS = 'steps';

export const DINE_IN_BATCHING_TYPE__GROUP = 'group';
export const DINE_IN_BATCHING_TYPE__BY_ITSELF = 'by-itself';

export const PLATFORM__ONLINE = 'online';
export const PLATFORM__POS = 'pos';

export const ITEM_SOLD_AT_PLATFORM__POS = 'pos';
export const ITEM_SOLD_AT_PLATFORM__ONLINE = 'online';
export const AVAILABILITY_STATUS__AVAILABLE = 'available';
export const AVAILABILITY_STATUS__SOLD_OUT = 'soldOut';
export const AVAILABILITY_STATUS__INVISIBLE = 'invisible';

// Item Button Image Border Style Setting Constants
export const ITEM_IMAGE_BORDER_STYLE = 'itemImageBorderStyle';
export const CIRCULAR_BORDER = 'circularBorder';
export const SQUARE_BORDER = 'squareBorder';
export const ROUNDED_BORDER = 'roundedBorder';
